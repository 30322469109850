<template>
  <div class="t-24 fw-7"> {{ player.full_name }} </div>
  <div v-if="fullyLoaded" class="m-tx4">
    <div class="f scroll-x p-bx2">
      <div class="column">
        <div class="cell"><div class="t">Year</div></div>
        <div class="cell bb"><div class="t">Week</div></div>
        <div class="cell"><div class="t">Proj</div></div>
        <div class="cell"><div class="t">Fpts</div></div>
      </div>
      <template v-for="year in Object.keys(player.metrics)">
        <template v-for="week in Object.keys(player.metrics[year])">
          <div v-if="metricsAnalysis[year][week].shouldDisplay" class="column">
            <div class="cell"><div class="t">{{ week === '1' ? year : null }}</div></div>
            <div class="cell bb"><div class="t">{{ week }}</div></div>
            <div class="cell">
              <div class="t">{{ metricsAnalysis[year][week].proj.value }}</div>
            </div>
            <div class="cell" :style="metricsAnalysis[year][week].fpts.style">
              <div class="t">{{ metricsAnalysis[year][week].fpts.value }}</div>
            </div>
          </div>
        </template>
      </template>
    </div>
    <div class="t-20 fw-5 m-tx6">
      <span> Avg. Diff: </span>
      <span :style="metricsAnalysis.diff.style"> {{ metricsAnalysis.diff.avg }} </span>
    </div>
    <div class="t-20 fw-5 m-tx6">
      <span> Total Diff: </span>
      <span :style="metricsAnalysis.diff.style"> {{ metricsAnalysis.diff.total }} </span>
    </div>
  </div>
  <BaseLoading v-else class="m-tx4" />
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useModalStore } from "../../../stores/modal_store.js";
  import { useFantasyStore } from "../../../stores/fantasy_store.js";
  const modalStore = useModalStore();
  const fantasyStore = useFantasyStore();

  const player = computed(() => modalStore.data.player);

  const fullyLoaded = ref(false);
  const fetchPlayerMetrics = () => {
    fantasyStore.fetchPlayerMetrics({ playerId: player.value.player_id, metricsType: 'stats' })
      .then(() => fantasyStore.fetchPlayerMetrics({ playerId: player.value.player_id, metricsType: 'projections' }))
      .then(() => fullyLoaded.value = true);
  };

  // Metrics Analyzer
  const metricsAnalysis = computed(() => {
    const analysis = {};
    let totalDiff = 0;
    let totalGames = 0;
    let dataHasStarted = false;
    Object.keys(player.value.metrics).forEach((year) => {
      analysis[year] = {};
      Object.keys(player.value.metrics[year]).forEach((week) => {
        const metrics = player.value.metrics[year][week];
        const projValue = pointsCalculator(metrics?.projections?.stats);
        const fptsValue = pointsCalculator(metrics?.stats?.stats);
        let fptsStyle;
        if (projValue && fptsValue) {
          dataHasStarted = true;
          const diff = fptsValue - projValue;
          totalDiff += diff;
          totalGames += 1;
          fptsStyle = { 'background-color': `${diffColorCalculator(diff)}60` };
        }
        analysis[year][week] = {
          shouldDisplay: dataHasStarted,
          proj: { value: projValue },
          fpts: { value: fptsValue, style: fptsStyle },
        }
      });
    });
    const avgDiff = (totalDiff / totalGames).toFixed(2);
    const prefix = totalDiff >= 0 ? '+' : '';
    analysis.diff = {
      total: `${prefix}${totalDiff.toFixed(2)}`,
      avg: `${prefix}${avgDiff}`,
      style: { color: diffColorCalculator(avgDiff) },
    };
    return analysis;
  });
  const pointsCalculator = (values) => {
    if (!values) return null;
    let points = 0;
    points += (0.04 * (values.pass_yd || 0));
    points += (4 * (values.pass_td || 0));
    points += (2 * (values.pass_2pt || 0));
    points += (-2 * (values.pass_int || 0));
    points += (0.1 * (values.rush_yd || 0));
    points += (6 * (values.rush_td || 0));
    points += (2 * (values.rush_2pt || 0));
    points += (0.5 * (values.rec || 0));
    points += (0.1 * (values.rec_yd || 0));
    points += (6 * (values.rec_td || 0));
    points += (2 * (values.rec_2pt || 0));
    points += (-2 * (values.fum_lost || 0));
    return points.toFixed(2);
  };
  const diffColorCalculator = (diff) => {
    if (diff >= 10) {
      return '#2FDEA1';
    } else if (diff > 2 && diff < 10) {
      return '#62CAB3';
    } else if (diff <= 2 && diff >= -2) {
      return '#94B7C4';
    } else if (diff < -2 && diff > -10 ) {
      return '#C6A4D6';
    } else if (diff <= -10) {
      return '#F990E8';
    }
  };

  onMounted(() => {
    fetchPlayerMetrics();
  });
</script>

<style scoped lang="css">
  .column {
    display: flex;
    flex-direction: column;
    width: 40px;
    min-width: 40px;
  }
  .column:first-child {
    width: 60px;
    min-width: 60px;
    position: sticky;
    left: 0px;
    float: left;
    background-color: white;
  }
  .cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    width: 100%;
  }
  .cell.bb {
    border-bottom: 1px solid black;
  }
</style>