<template>
  <div :class="`w-x60 br-16 m-rx2 m-bx2 p-x6 ${backgroundClass}`">
    <div class="f-sb f-ac">
      <div class="t-16 fw-5">{{ player.full_name }}</div>
      <span class="material-symbols-outlined c-p" @click="openPlayerModal"> open_in_new </span>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useModalStore } from "../../stores/modal_store.js";
  const modalStore = useModalStore();

  const { player } = defineProps(['player']);

  const backgroundClass = computed(() => {
    if (player.position === 'QB') return 'bg-seafoam-light';
    if (player.position === 'RB') return 'bg-cyan-light';
    if (player.position === 'WR') return 'bg-pink-light';
    if (player.position === 'TE') return 'bg-gray-light';
    return 'bg-onyx-light';
  });

  const openPlayerModal = () => {
    modalStore.updateModal({
      componentName: 'FantasyModalsPlayer',
      data: { player },
    });
  };
</script>