<template>
  <template v-if="selectedLeague">
    <FantasyDraftControls v-model="selectedLeagueId" class="m-tx4" />
    <div class="draft-table m-tx4">
      <div class="draft-row heading">
        <div class="draft-cell skinny"></div>
        <div v-for="draftSlot in numDraftSlots" class="draft-cell">
          <div class="t">Pick {{ draftSlot }}</div>
        </div>
      </div>
      <div v-for="draftRound in numDraftRounds" class="draft-row">
        <div class="draft-cell skinny">
          <div class="t">Round {{ draftRound }}</div>
        </div>
        <div v-for="draftSlot in numDraftSlots" class="draft-cell">
          <FantasyDraftPick
            :pick="arrangedPicks[draftRound][draftSlot]"
            :draft="selectedLeague.draft"
          />
        </div>
      </div>
    </div>
  </template>
  <BaseLoading v-else />
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useFantasyStore } from "../../stores/fantasy_store.js";
  const fantasyStore = useFantasyStore();

  const leagues = computed(() => fantasyStore.leagues);
  const selectedLeagueId = ref(null);
  const selectedLeague = computed(() => leagues.value.find(league => league.league_id === selectedLeagueId.value));
  const numDraftSlots = computed(() => Object.keys(selectedLeague.value.draft.draft_order).length);
  const numDraftRounds = computed(() => selectedLeague.value.draft.picks.length / numDraftSlots.value);

  const arrangedPicks = computed(() => {
    const rounds = {};
    selectedLeague.value.draft.picks.forEach((pick) => {
      if (!rounds[pick.round]) rounds[pick.round] = {};
      rounds[pick.round][pick.draft_slot] = pick;
    });
    return rounds;
  });

  const selectLeague = (league) => {
    selectedLeagueId.value = league.league_id;
  };

  onMounted(() => {
    fantasyStore.fetchDrafts().then(() => {
      selectLeague(leagues.value[0]);
    });
  });
</script>

<style scoped lang="css">
  .draft-table {
    display: flex;
    flex-direction: column;
    width: 96%;
    min-width: 1180px;
  }

  .draft-row {
    display: flex;
    padding: 4px 0;
  }

  .draft-cell.skinny {
    flex: 1;
  }
  .draft-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    flex: 2;
  }

  .draft-row { align-items: stretch; }
</style>