<template>
  <div class="f-w w-80p m-tx4">
    <div
      class="fantasy-menu-option bg-cyan"
      @click="selectView({ trades: true })"
      :style="{ 'background-image' : cyanGradient }"
    ><div class="t-32 fw-7">TRADES</div></div>
    <div
      class="fantasy-menu-option bg-pink"
      @click="selectView({ drafts: true })"
      :style="{ 'background-image' : pinkGradient }"
    ><div class="t-32 fw-7">DRAFTS</div></div>
    <div 
      v-for="user in users" 
      class="fantasy-menu-option" 
      @click="selectView({ user: user.user_id })"
      :style="{ 'background-image': backgroundImages[user.user_id], 'background-size': '100%', 'background-position': 'center' }"
    ><div class="t-32 fw-7"> {{ user.display_name }} </div></div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useFantasyStore } from '../../stores/fantasy_store.js';
  const fantasyStore = useFantasyStore();

  // Derived from values in colors.scss
  const cyanGradient = `linear-gradient(to left bottom, #FFFFFF20 0, #0BD2D320 100%)`;
  const pinkGradient = `linear-gradient(to left bottom, #FFFFFF20 0, #F990E820 100%)`;
  const seafoamGradient = `linear-gradient(to left bottom, #FFFFFF90 0, #2FDEA1 80%)`;

  const league = computed(() => fantasyStore.league);
  const users = computed(() => fantasyStore.users);
  const backgroundImages = computed(() => {
    const styles = {};
    users.value.forEach((user) => {
      const url = `url(https://sleepercdn.com/avatars/${user.avatar || league.value.avatar})`;
      styles[user.user_id] = `${seafoamGradient}, ${url}`;
    });
    return styles;
  });

  const selectView = (view) => {
    fantasyStore.selectView(view);
  };
</script>