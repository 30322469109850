<template>
  <div class="d-ib br-8 bg-pink">
    <div class="f">
      <div
        v-for="league in fantasyStore.leagues"
        :class="leagueSelectorClass(league.league_id)"
        @click="selectLeague(league)"
      >{{ league.season }}</div>
    </div>
    <div class="ta-c">
      <div class="t p-x2 c-p bright-hover">Analyze Full Draft</div>
    </div>
  </div>
</template>

<script setup>
  import { useFantasyStore } from "../../stores/fantasy_store.js";
  const fantasyStore = useFantasyStore();
  const props = defineProps(['modelValue']);
  const emit = defineEmits(['update:modelValue']);

  const selectLeague = (league) => {
    emit('update:modelValue', league.league_id);
  };

  const leagueSelectorClass = (leagueId) => {
    const brightnessClass = props.modelValue === leagueId ? 'bright' : 'bright-hover';
    return `t p-x2 c-p ${brightnessClass}`;
  };
</script>
