<template>
  <div class="f-v f-ac f-jc h-100p w-100p br-8 p-x1 bg-seafoam-light">
    <div class="t fw-5 text-wrap">{{ player.full_name }}</div>
    <div class="t text-wrap">{{ user?.display_name || '[missing user]' }}</div>
    <div v-if="statsSinceDraft" class="m-tx2 w-90p">
      <div class="f-sb w-100p">
        <div class="t-10"># Games:</div>
        <div class="t-10">{{ statsSinceDraft.totalGames }}</div>
      </div>
      <div class="f-sb w-100p">
        <div class="t-10">Total Points:</div>
        <div class="t-10">{{ statsSinceDraft.totalPoints }}</div>
      </div>
      <div class="f-sb w-100p">
        <div class="t-10">Avg Points:</div>
        <div class="t-10">{{ statsSinceDraft.avgPoints }}</div>
      </div>
    </div>
    <button v-else :class="`button m-tx1 ${fetchingStats ? 'disabled' : ''}`" @click="fetchStats">
      Get Stats
    </button>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useFantasyStore } from "../../stores/fantasy_store.js";
  const fantasyStore = useFantasyStore();

  const props = defineProps(['pick', 'draft']);

  const player = computed(() => fantasyStore.players[props.pick.player_id]);
  const user = computed(() => fantasyStore.users.find(user => user.user_id === props.pick.picked_by));

  const fetchingStats = ref(false);
  const fetchStats = () => {
    fetchingStats.value = true;
    fantasyStore.fetchPlayerMetrics({ playerId: props.pick.player_id, metricsType: 'stats' }).then(() => {
      fetchingStats.value = false;
    })
  };
  const statsSinceDraft = computed(() => {
    const metrics = player.value.metrics;
    if (metrics && !fetchingStats.value) {
      let totalPoints = 0;
      let totalGames = 0;
      const seasonsSinceDraft = Object.keys(metrics).filter((season) => {
        return parseInt(season) >= parseInt(props.draft.season);
      });
      seasonsSinceDraft.forEach((season) => {
        Object.keys(metrics[season]).forEach((week) => {
          const points = metrics[season][week].stats?.stats?.pts_half_ppr;
          if (points) {
            totalPoints += points;
            totalGames += 1;
          }
        })
      });
      return {
        totalPoints: totalPoints.toFixed(2),
        totalGames: totalGames.toFixed(2),
        avgPoints: totalPoints === 0 ? 0 : (totalPoints / totalGames).toFixed(2),
      };
    }
  });
</script>