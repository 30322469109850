import { defineStore } from "pinia";
import axios from 'axios';

export const useFantasyStore = defineStore("FantasyStore", {
  state: () => {
    return {
      selectedView: null,
      apiCallsInLastMinute: 0,
      leagues: [],
      nflState: {},
      rosters: [],
      users: [],
      transactions: [],
      players: {},
      playerIdsToFetch: [],
    };
  },

  getters: {
    league: (state) => {
      return state.leagues[0] || {};
    },

    trades: (state) => {
      const unorderedTrades = state.transactions.filter(transaction => transaction.type === 'trade');
      return unorderedTrades.sort((a, b) => a.created - b.created);
    },
  },

  actions: {
    selectView(view) {
      this.selectedView = view;
    },

    registerApiCall() {
      // This is to keep track of how many API calls I'm making to Sleeper so I don't exceed 1k a minute. Potentially overkill
      this.apiCallsInLastMinute += 1;
      setTimeout(() => {
        this.apiCallsInLastMinute -= 1;
      }, 60000);
    },

    fetchLeague(leagueId) {
      return new Promise((resolve) => {
        this.registerApiCall();
        axios.get(`/external_api/sleeper/league?league_id=${leagueId}`)
          .then(({ data }) => {
            this.leagues.push(data);
            if (data.previous_league_id && data.previous_league_id !== '0') {
              this.fetchLeague(data.previous_league_id).then(() => resolve(true));
            } else {
              resolve(true);
            }
          })
          .catch(() => {
            resolve(false);
          })
      });
    },

    fetchNflState() {
      return new Promise((resolve) => {
        this.registerApiCall();
        axios.get('/external_api/sleeper/nfl_state')
          .then(({ data }) => {
            this.nflState = data;
            resolve(true);
          });
      })
    },

    fetchRosters() {
      return new Promise((resolve) => {
        this.registerApiCall();
        axios.get(`/external_api/sleeper/rosters?league_id=${this.league.league_id}`)
          .then(({ data }) => {
            this.rosters = data;
            data.forEach((roster) => {
              roster.players.forEach(playerId => this.preparePlayerIdForFetching(playerId));
            });
            resolve(true)
          });
      });
    },

    fetchUsers() {
      return new Promise((resolve) => {
        this.registerApiCall();
        axios.get(`/external_api/sleeper/users?league_id=${this.league.league_id}`)
          .then(({ data }) => {
            this.users = data;
            resolve(true)
          });
      });
    },

    preparePlayerIdForFetching(id) {
      // Call this when we have loaded playerIds elsewhere that we think we'll need to load up
      if (!this.playerIdsToFetch.includes(id)) this.playerIdsToFetch.push(id);
    },

    fetchPlayers() {
      return new Promise((resolve) => {
        const idsParam = this.playerIdsToFetch.join('-')
        axios.get(`/external_api/sleeper/players?player_ids=${idsParam}`)
          .then(({ data }) => {
            this.players = { ...this.players, ...data };
            resolve(true);
          });
      });
    },

    // Transactions
    fetchTransactions() {
      return new Promise((resolve) => {
        this.fetchTransactionsForLeague({ leagueIndex: 0 }).then(() => resolve(true));
      });
    },
    fetchTransactionsForLeague({ leagueIndex }) {
      return new Promise((resolve) => {
        const leagueId = this.leagues[leagueIndex].league_id;
        this.fetchTransactionsForLeagueForWeek({
          leagueId,
          currentWeek: 1,
          finalWeek: this.nflState.week
        }).then(() => {
          if (leagueIndex < this.leagues.length - 1) {
            this.fetchTransactionsForLeague({ leagueIndex: leagueIndex + 1 }).then(() => resolve(true));
          } else {
            resolve(true);
          }
        });
      });
    },
    fetchTransactionsForLeagueForWeek({ leagueId, currentWeek, finalWeek }) {
      return new Promise((resolve) => {
        this.registerApiCall();
        axios.get(`/external_api/sleeper/transactions?league_id=${leagueId}&round=${currentWeek}`)
          .then(({ data }) => {
            this.transactions = this.transactions.concat(data);
            data.forEach((transaction) => {
              if (transaction.adds) Object.keys(transaction.adds).forEach(playerId => this.preparePlayerIdForFetching(playerId));
              if (transaction.drops) Object.keys(transaction.drops).forEach(playerId => this.preparePlayerIdForFetching(playerId));
            });
            if (currentWeek < finalWeek) {
              this.fetchTransactionsForLeagueForWeek({
                leagueId,
                currentWeek: currentWeek + 1,
                finalWeek: finalWeek
              }).then(() => {
                resolve(true);
              });
            } else {
              resolve(true);
            }
          });
      });
    },

    // Player Metrics
    fetchPlayerMetrics({ playerId, metricsType }) {
      return new Promise((resolve) => {
        this.fetchPlayerMetricsForLeague({ playerId, leagueIndex: 0, metricsType }).then(() => resolve(true));
      });
    },
    fetchPlayerMetricsForLeague({ playerId, leagueIndex, metricsType }) {
      const league = this.leagues[leagueIndex];
      const year = league.season;
      const player = this.players[playerId];
      return new Promise((resolve) => {
        if (player.metrics && player.metrics[year] && player.metrics[year][metricsType]) {
          // We've already loaded this player's metrics at some point
          resolve(true);
        } else {
          // We have not loaded this player's metrics and need to hit the API
          this.registerApiCall();
          axios.get(`/external_api/sleeper/player_${metricsType}?player_id=${playerId}&year=${year}`)
            .then(({ data }) => {
              if (!player.metrics) player.metrics = {};
              if (!player.metrics[year]) player.metrics[year] = {};
              Object.keys(data).forEach((week) => {
                if (!player.metrics[year][week]) player.metrics[year][week] = {};
                player.metrics[year][week][metricsType] = data[week];
              });
              if (leagueIndex < this.leagues.length - 1) {
                this.fetchPlayerMetricsForLeague({ playerId, leagueIndex: leagueIndex + 1, metricsType })
                  .then(() => resolve(true));
              } else {
                resolve(true);
              }
            })
        }
      });
    },

    // Drafts
    fetchDrafts() {
      return new Promise((resolve) => {
        this.fetchDraftsForLeague({ leagueIndex: 0 }).then(() => {
          this.fetchDraftPicksForLeague({ leagueIndex: 0 }).then(() => resolve(true));
        });
      });
    },
    fetchDraftsForLeague({ leagueIndex }) {
      const league = this.leagues[leagueIndex];
      return new Promise((resolve) => {
        this.registerApiCall();
        axios.get(`/external_api/sleeper/drafts?league_id=${league.league_id}`)
          .then(({ data }) => {
            const draft = data[0]; // There's usually only one. Year 1 had more, but it was the first.
            this.leagues[leagueIndex].draft = draft;
            if (leagueIndex < this.leagues.length - 1) {
              this.fetchDraftsForLeague({ leagueIndex: leagueIndex + 1 }).then(() => resolve(true));
            } else {
              resolve(true);
            }
          })
      });
    },
    fetchDraftPicksForLeague({ leagueIndex }) {
      const league = this.leagues[leagueIndex];
      return new Promise((resolve) => {
        this.registerApiCall();
        axios.get(`/external_api/sleeper/draft_picks?draft_id=${league.draft.draft_id}`)
          .then(({ data }) => {
            this.leagues[leagueIndex].draft.picks = data;
            if (leagueIndex < this.leagues.length - 1) {
              this.fetchDraftPicksForLeague({ leagueIndex: leagueIndex + 1 }).then(() => resolve(true));
            } else {
              resolve(true);
            }
          })
      });
    },
  },
});
